import SearchIconSvg from "@lagora/assets/icons/magnifying-glass.svg?react";
import { supabase } from "@lagora/utils/supabase";
import isMobile from "is-mobile";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { setModalItemId } from "../store";
import type { RequestStates } from "../types";
import Button from "./Button.component";
import Card from "./Card.component";
import Modal from "./Modal/Modal.component";

type SearchStatus = RequestStates | "empty";

// NOTE: CSS class must be declared in the Astro component
// in order to have them processed by the bundler
// apps/website/src/layouts/components/Modal.component.astro
const modalClasssWidth: Record<
  SearchStatus,
  Array<
    | "ModalBox--ReducedWidth"
    | "ModalBox--ExpandedWidth"
    | "ModalBox--FullHeight"
    | "ModalBox--MobileKeyboardOpened"
  >
> = {
  idle: ["ModalBox--ReducedWidth"],
  pending: ["ModalBox--ExpandedWidth", "ModalBox--FullHeight"],
  error: ["ModalBox--ReducedWidth"],
  success: ["ModalBox--ExpandedWidth", "ModalBox--FullHeight"],
  empty: ["ModalBox--ReducedWidth"],
};

export default function Search() {
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchStatus, setSearchStatus] = useState<SearchStatus>("idle");
  const [search, setSearch] = useState("");
  const [results, setResults] = useState<
    Array<{
      id: string;
      poll: {
        description: string;
        id: string;
        name: string;
      };
    }>
  >([]);

  const modalClasses = modalClasssWidth[searchStatus];

  useEffect(() => {
    const handleKeyboardVisible = () => {
      modalClasses.push("ModalBox--MobileKeyboardOpened");
    };

    if ("virtualKeyboard" in navigator) {
      // FIXME: unwanted types
      ((navigator.virtualKeyboard as any).addEventListener as any)(
        "geometrychange",
        handleKeyboardVisible,
      );
    }
  }, [modalClasses]);

  const searchResults = (internalSearch: string) => {
    supabase
      .from("results")
      .select("id,poll:polls!inner(id,name,description)")
      .ilike("poll.name", `%${internalSearch}%`)
      .limit(30)
      .then(({ data, error }) => {
        setSearchStatus((currentSearchStatus) => {
          if (currentSearchStatus !== "pending") {
            return currentSearchStatus;
          }

          if (error) {
            return "error";
          }

          setResults(() => data);

          return data.length > 0 ? "success" : "empty";
        });
      });
  };

  const debouncedSearchResults = useCallback(debounce(searchResults, 1500), []);

  useEffect(() => {
    if (search.length < 3) {
      setSearchStatus("idle");
      setResults([]);

      return;
    }

    setSearchStatus("pending");

    debouncedSearchResults(search);
  }, [search, debouncedSearchResults]);

  useEffect(() => {
    // NOTE: Hide keyboard on mobile when
    // there are results offers a better UX
    if (searchStatus === "success" && isMobile()) {
      inputRef.current?.blur();
    }
  }, [searchStatus]);

  return (
    <>
      <div className="hidden md:block">
        <Button
          Icon={SearchIconSvg}
          aria-label="Rechercher"
          className="btn btn-neutral md:btn-block"
          data-testid="Search__OpenButton"
          event="open_search"
          onClick={() => {
            setSearchStatus("idle");
            setModalItemId("Search");
          }}
        >
          Rechercher
        </Button>
      </div>

      <Button
        Icon={SearchIconSvg}
        aria-label="Rechercher"
        className="btn btn-circle btn-neutral md:hidden"
        data-testid="Search__OpenButton--Mobile"
        event="open_search"
        onClick={() => {
          setModalItemId("Search");
        }}
      />

      <Modal
        id="Search"
        // NOTE: h-56 is an arbitrary value
        // It's necessary to precise in order to be able
        // to have animation on the height
        className={modalClasses.join(" ").concat(" h-56")}
        onClose={() => {
          setSearchStatus("idle");
        }}
        onOpen={() => {
          inputRef.current?.focus();
        }}
      >
        <div className="p-2">
          <label
            className="Input w-full flex items-center gap-2"
            htmlFor="Search__Input"
          >
            <input
              className="grow"
              data-testid="Search__Input"
              id="Search__Input"
              onChange={({ target }) => {
                setSearch(target.value);
              }}
              placeholder="Rechercher"
              ref={inputRef}
              type="search"
              value={search}
            />

            {searchStatus === "pending" && (
              <span className="loading loading-spinner loading-sm" />
            )}
          </label>

          {searchStatus === "idle" && (
            <div className="text-base-content text-center font-bold mt-2 text-balance">
              Veuillez saisir au moins 3 lettres pour lancer la recherche
            </div>
          )}

          {searchStatus === "error" && (
            <div className="text-base-content text-center font-bold mt-2 text-balance">
              La recherche n'a pu aboutir, merci de ré-essayer dans 10 minutes
            </div>
          )}

          {searchStatus === "empty" && (
            <div className="text-base-content text-center font-bold mt-2 text-balance">
              Aucun résultat trouvé pour votre recherche. Veuillez ré-essayer
            </div>
          )}

          <div className="pt-1 pb-3 mt-2 flex flex-1 flex-wrap gap-3">
            <div
              data-testid="Search__ResultsList"
              className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full"
            >
              {searchStatus === "pending" &&
                new Array(10)
                  .fill(null)
                  .map((_item, index) => index)
                  .map((item) => {
                    return (
                      <div key={`SearchPlaceholder--${item}`}>
                        <Card loading />
                      </div>
                    );
                  })}

              {searchStatus === "success" &&
                results.map((result, resultKey) => {
                  return (
                    <div
                      data-testid={`Search__ResultItem--${resultKey}`}
                      key={result.id}
                    >
                      <Card
                        body={result.poll.description}
                        href={`/results/${result.id}`}
                        title={result.poll.name}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
